import React from "react";
import { Button } from "../../../ui/button";
import FavhikerImg from "../../../../../assets/images/recent_work/first_img_rw.png";
import CloudAdminImg from "../../../../../assets/images/recent_work/second_img_rw.png";
import FamyCareImg from "../../../../../assets/images/recent_work/famycare_img_rw.png";
import AcceronImg from "../../../../../assets/images/recent_work/acceron_img_rw.png";
import GateImg from "../../../../../assets/images/recent_work/gate_img_rw.png";
import KSGlobalImg from "../../../../../assets/images/recent_work/ksglobal_img_rw.png";

// Define the props type for the component
interface RecentWorksProps {
  openEnquiryForm: () => void;
}

// Define the type for work items
interface WorkItem {
  id: number;
  image_url: string;
  title: string;
  description: string;
}

const RecentWorks: React.FC<RecentWorksProps> = ({ openEnquiryForm }) => {
  const workItems: WorkItem[] = [
    {
      id: 0,
      image_url: FavhikerImg,
      title: "Favhiker",
      description:
        "FavHiker (Flagship Product): A social food discovery app with 12,000 users, offering personalized recommendations and unique culinary experiences. It fosters a vibrant community of food enthusiasts, allowing users to explore favorite dishes and local gems. With real-time insights and strong security features, FavHiker ensures a safe and engaging platform for all food lovers.",
    },
    {
      id: 1,
      image_url: CloudAdminImg,
      title: "Delnie Cloud Admin",
      description:
        "Delnie Cloud Admin is designed to efficiently manage master data, enabling easy analysis through Mongo charts and seamless API creation for streamlined operations. It provides centralized data management for all employees, complete with role-level restrictions to enhance security and access control.",
    },
    {
      id: 2,
      image_url: FamyCareImg,
      title: "Famy Care",
      description:
        "Famycare is a trusted Registered NDIS provider dedicated to empowering individuals with disabilities across Greater Melbourne and Regional Victoria. With a focus on comprehensive support services and 24/7 on-call registered nurse assistance, we are committed to enhancing the quality of life for our participants, helping them achieve independence and pursue their goals with confidence.",
    },
    {
      id: 3,
      image_url: AcceronImg,
      title: "Acceron",
      description: "Acceron Industries is a premier provider of automotive lubrication solutions, dedicated to enhancing engine performance through cutting-edge technology. With a strong emphasis on quality and innovation, we deliver comprehensive products and services that meet the demands of the automotive sector while promoting sustainability and efficiency.",
    },
    {
      id: 4,
      image_url: GateImg,
      title: "Gate Industrial Solutions",
      description:
        "GATE Industrial Solutions is a leading supplier specializing in innovative industrial products, delivering tailored solutions to clients across various sectors. With a focus on quality and reliability, we leverage advanced technology and expertise to meet the unique needs of our customers, ensuring efficient operations and enhanced productivity.",
    },
    {
      id: 5,
      image_url: KSGlobalImg,
      title: "KS Global",
      description:
        "KS Global is a trusted exporter with over 15 years of experience, providing high-quality products to diverse markets from Poland to Dubai. Our commitment to excellence and sustainability ensures that each product meets the highest standards while supporting local communities.",
    },
  ];

  return (
    <div className="px-6 md:px-20 py-16 bg-gray-50">
      <h3 className="font-sans font-bold text-4xl md:text-6xl leading-tight text-center text-gray-900 mb-12">
        Recent Works
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12 md:gap-16">
        {workItems.map((item) => (
          <div
            key={item.id}
            onClick={openEnquiryForm} // Make the entire card clickable
            className="cursor-pointer bg-white border border-gray-200 rounded-xl overflow-hidden shadow-lg transform transition-all hover:scale-105 hover:shadow-2xl hover:border-transparent"
          >
            <div className="relative overflow-hidden">
              <img
                src={item.image_url}
                alt={item.title}
                // className="w-full h-auto max-w-full transition-transform duration-500 ease-in-out hover:scale-110"
                className="w-full h-auto max-w-full"
              />
            </div>
            <div className="px-6 py-6">
              <h4 className="font-sans font-bold text-lg md:text-xl mb-3 text-gray-800">
                {item.title}
              </h4>
              <p className="font-sans text-base text-gray-600 mb-4">
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Single Enquire Now button at the bottom */}
      <div className="text-center mt-12">
        <Button
          onClick={openEnquiryForm}
          className="py-4 px-6 bg-gradient-to-r from-blue-600 to-purple-600 text-white text-lg font-semibold rounded-lg hover:from-purple-600 hover:to-blue-600 transition duration-300 ease-in-out shadow-lg focus:outline-none focus:ring-2 focus:ring-purple-600"
        >
          Explore Our Work
        </Button>
      </div>
    </div>
  );
};

export default RecentWorks;
