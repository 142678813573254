import "./index.css";
import billd from "../../../assets/images/products/billd.png";

import Custom from "./home-custom-part";
import WhyDelnie from "../products/why-del/why-delnie";
import JoinSquad from "./join-squad";
import Squad from "../squad/squad";
import Carousel from "./banner-home";
import { useEffect } from "react";
import ProductTabs from "../products/products-tabs";

import HomeDelnieBooks from "./home-cloud-admin";
import HomeFavhiker from "./home-favhiker";
import HomeCloudAdmin from "./home-cloud-admin";
import HomeAboutPage from "./home-about-us";
import HomeOurServices from "./home-our-services";
import SEO from "../../common/SEO";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <HomeBanner /> */}
      {/* <div className="container-fluid index_body">
        <div className="row folio">
          <h1 className="portfolio section-title">Our Product Portfolio</h1>
        </div>
        <div className="row portfolio_row">
          <div id="demo" className="carousel slide" data-ride="carousel">
            <ul className="carousel-indicators">
              <li data-target="#demo" data-slide-to="0" className="active"></li>
              <li data-target="#demo" data-slide-to="1"></li>
              <li data-target="#demo" data-slide-to="2"></li>
            </ul>
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="row carousel-row">
                  <div className="col-sm-12 col-md-7 col-lg-7">
                    <img
                      src={billd}
                      alt="delnie logo"
                      className="img-fluid"
                    ></img>
                  </div>
                  <div className="col-sm-12 col-md-5 col-lg-5 billd-details">
                    <h1>
                      Delnie <span>Books</span>
                    </h1>
                    <p>the next-gen invoice and accounting ERP</p>
                    <p>
                      Delnie Books is our take on invoice generation and
                      accounting system. Manage invoices, get insights,
                      reminders and avoid late payments, all in one platform.{" "}
                    </p>
                    <p>Key features</p>
                    <p>Generate Invoice</p>
                    <p>Client wise summary</p>
                    <p>Invoice Master Data Validation</p>
                    <p>Consolidated BankStatement and Tally</p>
                    <p>Financial Insights</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Carousel />
      <HomeAboutPage />
      <HomeFavhiker />
      <HomeCloudAdmin />
      <HomeOurServices />
      <WhyDelnie />
      <Custom />
      <JoinSquad />
    </>
  );
}
export default Home;
