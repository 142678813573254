import { Button } from "../../../ui/button";
import PhoneCallIcon from "../../../../../assets/images/website-service/phone_call.svg";
import WhatsappIcon from "../../../../../assets/images/website-service/whatsapp_icon.svg";


export default function ServiceFooter(props:any) {

    return (
      <>
        {/* Desktop View */}
        <div className="hidden md:block bg-[#1C1C1C] py-16 px-24">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="font-roboto font-bold text-[#FFFFFF] text-3xl mb-6">
                Need Expert Guidance?
              </h3>
              <p className="font-lato text-[#E0E0E0] text-base mb-4">
                Get personalized advice and consultancy from our experts at every
                step of your journey.
              </p>
              <h4 className="font-lato font-bold text-[#FFFFFF] text-lg">
                Book your free consultation today!
              </h4>
            </div>
            <div className="flex space-x-6">
              <Button
                className="flex items-center h-[50px] px-[50px] bg-gradient-to-r from-blue-500 to-purple-600 hover:from-purple-600 hover:to-blue-500 rounded-full shadow-lg hover:shadow-2xl transition-shadow duration-300"
                onClick={props.handlePhoneCall}
              >
                <img src={PhoneCallIcon} alt="Phone" className="mr-3" />
                <span className="font-lato font-bold text-[#FFFFFF] text-base">
                  Call us
                </span>
              </Button>
              <Button
                className="flex items-center h-[50px] px-[50px] bg-green-600 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-600 rounded-full shadow-lg hover:shadow-2xl transition-shadow duration-300"
                onClick={props.handleWhatsApp}
              >
                <img src={WhatsappIcon} alt="WhatsApp" className="mr-3" />
                <span className="font-lato font-bold text-[#FFFFFF] text-base">
                  Whatsapp Us
                </span>
              </Button>
            </div>
          </div>
        </div>
  
        {/* Mobile View */}
        <div className="md:hidden flex justify-between items-center p-6 bg-[#1C1C1C]">
          <div>
            <h4 className="font-lato font-bold text-lg text-[#FFFFFF] mb-1">
              Need Guidance?
            </h4>
            <p className="font-lato text-sm text-[#E0E0E0]">
              Get expert advice at every stage.
            </p>
          </div>
          <div className="flex space-x-4 items-center">
            <Button className="p-0 bg-transparent">
              <a href="tel:9846280132" className="w-[28px] h-[28px]">
                <img src={PhoneCallIcon} alt="Phone" />
              </a>
            </Button>
            <Button className="p-0 bg-transparent">
              <a
                href="https://wa.me/9846280132"
                className="w-[28px] h-[28px]"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={WhatsappIcon} alt="WhatsApp" />
              </a>
            </Button>
          </div>
        </div>
      </>
    );
  }