import "./why-delnie.css";
import Clock from "../../../../assets/images/why-delnie/clock.svg";
import Hand from "../../../../assets/images/why-delnie/hand.svg";
import Screw from "../../../../assets/images/why-delnie/screw.svg";
function WhyDelnie() {
  return (
    <div className="container-fluid why_delnie_page">
      <div className="row why_del">
        <h1 className="why_delnie section-title">Why Delnie</h1>
        <p className="identity">What makes us different is our identity</p>
      </div>
      <div className="row features">
        <div className="col-lg-1 col-sm-2 symbol">
          <img src={Clock} alt="clock" className="symbol_img"></img>
        </div>
        <div className="col-lg-11 col-sm-10 wd_hd_col">
          <h1 className="feature_head">Having trouble, we got your back</h1>
        </div>
      </div>

      <div className="row feature_para_row">
        <p className="feature_para">
          We know the importance of our services for your business, thus we have
          the quickest response time in the industry. How quick? Our average
          response time is less than 3 minutes! That’s 4x faster than the
          quickest competitor. We make sure to minimise downtime and 24/7
          support of Services.
        </p>
      </div>

      <div className="row features">
        <div className="col-lg-1 col-sm-2 symbol">
          <img src={Hand} alt="hand" className="symbol_img"></img>
        </div>
        <div className="col-lg-11 col-sm-10 wd_hd_col">
          <h1 className="feature_head">The Most Value for your Money</h1>
        </div>
      </div>
      <div className="row feature_para_row">
        <p className="feature_para">
          Our products are simple and effective, and we commit to that. We make
          sure our products have an impact on your business. We don’t have
          complicated terminologies for our products, its simple to understand
          and gives the most value for your money.
        </p>
      </div>

      <div className="row features">
        <div className="col-lg-1 col-sm-2 symbol">
          <img src={Screw} alt="screw" className="symbol_img"></img>
        </div>
        <div className="col-lg-11 col-sm-10 wd_hd_col">
          <h1 className="feature_head">Customise & Implement</h1>
        </div>
      </div>
      <div className="row feature_para_row">
        <p className="feature_para">
          Even if you choose from our existing suite of products we customise
          that to match your requirement and thus creating effective impact on
          your businesses. Have a specific requirement? we got that covered too!{" "}
        </p>
      </div>
    </div>
  );
}
export default WhyDelnie;
