import "./index.css";
import favhikerimage from "../../../../assets/images/products/favhiker.gif";
import { Link } from "react-router-dom";

function ProductFavhiker() {
  return (
    <div className="container-fluid favhiker_tab">
      <div className="row favhiker_content_header">
        <h1>Taste Connect and Award</h1>
        <h2>Your Social Gateway to the Culinary World</h2>
        <p>
          Your personalised culinary companion, reshaping city dining. Discover
          curated hidden gems, tailored to your unique taste. Immerse in
          savouring local flavours—a delicious adventure awaits.
        </p>
      </div>
      <div className="row favhiker_products_details">
        <div className="col-lg-12 col-sm-12 favhiker_products_image_col">
          <img src={favhikerimage} alt="" className="img-fluid " style={{}} />
        </div>
        <div className="col-lg-12 col-sm-12 favhiker_products_details_col detail_col">
          <a
            href="https://www.favhiker.com/#home"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button>Visit Website</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ProductFavhiker;
