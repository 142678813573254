import React from "react";

const RefundPolicy = () => {
  return (
    <div
      className="refund-policy"
      style={{ textAlign: "start", padding: "8rem" }}
    >
      <h1 style={{ textAlign: "center", padding: "8rem" }}>Refund Policy</h1>

      <section>
        <h2 style={{ color: "white" }}>Refunds</h2>
        <p>
          FavHiker offers a subscription service priced at ₹99. Please note that
          this subscription fee is non-refundable once processed. By subscribing
          to FavHiker, you acknowledge that you have access to the full features
          of our service from the moment the payment is successful, and thus, no
          refund will be provided.
        </p>
        <p>
          If you face any technical issues with the subscription or feel that
          there has been an error in the billing process, please reach out to
          our support team at{" "}
          <a href="mailto:christo.john@delnie.com">christo.john@delnie.com</a>{" "}
          for assistance. We will review the issue on a case-by-case basis. If a
          refund is approved due to a technical issue or billing error, it will
          typically be processed within 5-7 business days. Please note that the
          exact time it takes for the refund to be credited to your account may
          vary depending on your payment provider.
        </p>
      </section>

      <section>
        <h2 style={{ color: "white" }}>Cancellation</h2>
        <p>
          You may cancel your FavHiker subscription at any time through your
          account settings. Please note that canceling the subscription will
          stop future charges, but no refund will be issued for the current
          billing period. After cancellation, you will retain access to the
          service until the end of the current billing cycle.
        </p>
      </section>

      <section>
        <h2 style={{ color: "white" }}>
          Late or Missing Refunds 
        </h2>
        <p>
          If a refund is approved due to a technical issue or billing error, and
          you have not yet received it, please first check your bank or payment
          method for any pending transactions. If the refund is still missing,
          contact your payment provider as it may take some time for the refund
          to officially post. If you’ve completed these steps and still haven’t
          received your refund, contact us at{" "}
          <a href="mailto:christo.john@delnie.com">christo.john@delnie.com</a>.
        </p>
      </section>
    </div>
  );
};

export default RefundPolicy;
