import axios from "axios";

// const API_URL = process.env.REACT_APP_API_URL
// const API_URL = "http://localhost:3021/api/";
// const ADMIN_API_URL = "http://localhost:3071/api/";
// const ADMIN_API_URL = https://api-admin-tsfavhiker.delnie.com/

const FILE_SERVER_URL = "https://api-favhiker.delnie.com/api/";
const API_URL = "https://favadminapi.delnie.com/api/";
const ADMIN_API_URL = "https://favadminapi-ts.delnie.com/api/";

// let accessToken = "JWT " + localStorage.getItem("accessToken") || null;

// const FILE_SERVER_URL = "http://localhost:3026/api/";
// const serverURL = "https://as.carrrie.in/api/"
// const serverURL=env.server_url;

let stateAccessToken: any = null;

export function setAccessToken(token: string) {
  console.log(token);

  stateAccessToken = token;
  console.log("stateAccessToken", stateAccessToken);
}

let accessToken = stateAccessToken || localStorage.getItem("accessToken");

async function resolve(promise: any) {
  const resolved = {
    data: null,
    error: null,
  };
  try {
    resolved.data = await promise;
  } catch (e: any) {
    resolved.error = e;
  }
  return resolved;
}

export async function get(path: string, urlParams: object) {
  let accessToken = stateAccessToken || localStorage.getItem("accessToken");
  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken};
  let options = { headers: headers, params: urlParams };
  return axios.get<any>(FULL_URL, options);
}

export async function customerGet(path: string, urlParams: object) {
  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken };
  let options = { headers: headers, params: urlParams };
  return axios.get<any>(FULL_URL, options);
}

export async function dashboardSummaryGet(path: string, urlParams: object) {
  let FULL_URL = ADMIN_API_URL + path;
  let headers = { Authorization: accessToken, organization_id: 1002 };
  let options = { headers: headers, params: urlParams };
  return axios.get<any>(FULL_URL, options);
}

export async function restaurantItemGet(path: string, urlParams: object) {
  let FULL_URL = ADMIN_API_URL + path;
  let headers = { Authorization: accessToken, organization_id: 1002 };
  let options = { headers: headers, params: urlParams };
  return axios.get<any>(FULL_URL, options);
}

export async function issueReportsGet(path: string, urlParams: object) {
  let FULL_URL = ADMIN_API_URL + path;
  let headers = { Authorization: accessToken, organization_id: 1002 };
  let options = { headers: headers, params: urlParams };
  return axios.get<any>(FULL_URL, options);
}

export async function awsget(path: string, urlParams: object) {
  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken, organization_id: 1002 };
  let options = { headers: headers, params: urlParams };
  return axios.get<any>(FULL_URL, options);
}
export async function awsupload(path: string, data: object) {
  let url = API_URL + path;
  console.log(API_URL, url);
  let headers = {
    Authorization: accessToken,
    organization_id: 1002,
  };
  let options = { headers: headers };
  return await resolve(axios.post(url, data, options).then((res) => res.data));
}

export async function post(path: string, data: object) {
  let accessToken = stateAccessToken || localStorage.getItem("accessToken");
  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken };
  let options = { headers: headers };
  return axios
    .post(FULL_URL, data, options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      if (err.statusCode == 401) {
        localStorage.setItem("accessToken", "");
      }
    });
}

export async function custom_post(path: string, data: object) {
  let accessToken = stateAccessToken || localStorage.getItem("accessToken");
  let FULL_URL = ADMIN_API_URL + path;
  let headers = { Authorization: accessToken };
  let options = { headers: headers };
  return axios
    .post(FULL_URL, data, options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      if (err.statusCode == 401) {
        localStorage.setItem("accessToken", "");
      }
    });
}

export async function patch(path: string, data: object) {
  let accessToken = stateAccessToken || localStorage.getItem("accessToken");

  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken };
  let options = { headers: headers };
  return axios
    .patch(FULL_URL, data, options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function put(path: string, data: object) {
  let accessToken = stateAccessToken || localStorage.getItem("accessToken");
  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken };
  let options = { headers: headers };
  return axios
    .put(FULL_URL, data, options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function login(path: string, data: object) {
  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken };
  let options = { headers: headers };
  return axios
    .post(FULL_URL, data, options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      if (err.statusCode == 401) {
        localStorage.setItem("id_token", "");
      }
    });
}

export async function getProfile() {
  // const navigate = useNavigate();
  let accessToken = stateAccessToken || localStorage.getItem("accessToken");

  let FULL_URL = API_URL + "getProfile";
  let headers = { Authorization: accessToken };
  let options = { headers: headers };
  console.log(FULL_URL);
  console.log(headers);

  console.log(options);

  return axios
    .get(FULL_URL, options)
    .then((res) => {
      console.log(res.data);
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      if (err && accessToken) {
        localStorage.setItem("accessToken", "");
        // navigate("/login");
        // window.location.replace("/login");
      }
    });
}

export async function register(path: string, data: object) {
  let FULL_URL = API_URL + path;
  let headers = { Authorization: accessToken };
  let options = { headers: headers };
  return axios
    .post(FULL_URL, data, options)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      if (err.statusCode == 401) {
        localStorage.setItem("id_token", "");
      }
    });
}

const fileServerAuthKey =
  "CARRIEfMA0GCSqGSIb3DQEBAQdqDup1pgSc0tQUMQUAA4GNADCBiQKBgQD3apAg6H2iUPLOADSERVER";
