import React, { useState } from "react";
import "./index.css";

interface FormState {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  experienceYears: number;
  currentRole: string;
  currentSalary: number;
  expectedSalary: number;
  currentLocation: string;
  expectedLocation: string;
  dob: string;
  setGender: string;
  resume: File | null;
  coverLetter: string;
}

const ApplicationForm: React.FC = () => {
  const [formState, setFormState] = useState<FormState>({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    experienceYears: 0,
    currentRole: "",
    currentSalary: 0,
    expectedSalary: 0,
    currentLocation: "",
    expectedLocation: "",
    dob: "",
    setGender: "",
    resume: null,
    coverLetter: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement |HTMLSelectElement>) => {   
     const { name, value } = event.target; //        let value=event.target.name;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // submit form data to the server
    console.log(formState);
  };

  return (
    <div className="container-fluid application">
      <div className="row form_android">
        <div className="col-lg-12 col-md-6 col-sm-12">
          <h1 className="android">Android Developer - SDE-I</h1>
          <p className="loc_exp">
            <span className="mx-2 dot"></span>
            <span>Bangalore</span>
            <span className="mx-2 dot"></span>
            <span>5+ years Experience</span>
          </p>
        </div>
      </div>
      <div className="row form_content">
        <div className="col-lg-6 col-md-6 col-sm-12 form_left">
          <h1 className="thisjob">Apply for this job</h1>
          <br />
          <br />
        </div>
      </div>

      <form  onSubmit={handleSubmit}>
        <div className="row form_content">
          <div className="col-lg-3 col-md-6 col-sm-12 form_left">
            <div className="mb-4">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className="form-control"
                id="firstName"
                placeholder="First Name"
                name="firstName"
                value={formState.firstName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 form_right">
            <div className="mb-4">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>

              <input
                type="text"
                className="form-control"
                id="lastName"
                placeholder="Last Name"
                name="lastName"
                value={formState.lastName}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12 form_left">
            <div className="mb-4">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Email"
                name="email"
                value={formState.email}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 form_right">
            <div className="mb-4">
              <label htmlFor="mobileNumber" className="form-label">
                Mobile Phone
              </label>
              <input
                type="number"
                className="form-control"
                id="mobileNumber"
                placeholder="Mobile Phone"
                name="mobileNumber"
                value={formState.mobileNumber}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row form_content">
          <div className="col-lg-5 col-md-6 col-sm-12 form_left">
            <div className="mb-4">
              <label htmlFor="experienceYears" className="form-label">
                Experience (in years)
              </label>
              <input
                type="number"
                className="form-control"
                id="experienceYears"
                placeholder="Experience (in years)"
                name="experienceYears"
                value={formState.experienceYears}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-lg-5 col-md-6 col-sm-12 form_right">
            <div className="mb-4">
              <label htmlFor="currentRole" className="form-label">
                Current Role
              </label>
              <input
                type="text"
                className="form-control"
                id="currentRole"
                placeholder="Current Role"
                name="currentRole"
                value={formState.currentRole}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="row form_content">
          <div className="col-md-5 col-sm-12 form_left">
            <div className="mb-4">
              <label htmlFor="currentSalary" className="form-label">
                Current Salary
              </label>
              <input
                type="number"
                className="form-control"
                id="currentSalary"
                placeholder="Current Salary"
                name="currentSalary"
                value={formState.currentSalary}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-5 col-sm-12 form_left">
            <div className="mb-4">
              <label htmlFor="expectedSalary" className="form-label">
                Expected Salary
              </label>
              <input
                type="number"
                className="form-control"
                id="expectedSalary"
                placeholder="expected Salary"
                name="Expected Salary"
                value={formState.expectedSalary}
                onChange={handleChange}
              />
            </div>
          </div>

        </div>

        <div className="row form_content">
          <div className="col-md-5 col-sm-12 form_left">
            <div className="mb-4">
              <label htmlFor="currentLocation" className="form-label">
                Current Location
              </label>
              <input
                type="text"
                className="form-control"
                id="currentLocation"
                placeholder="Current Salary"
                name="Current Location"
                value={formState.currentLocation}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="col-md-5 col-sm-12 form_left">
            <div className="mb-4">
              <label htmlFor="expectedLocation" className="form-label">
                Expected Location
              </label>
              <input
                type="text"
                className="form-control"
                id="expectedLocation"
                placeholder="Expected Location"
                name="Expected Location"
                value={formState.expectedLocation}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="row form_content">
      <div className="col-md-5 col-sm-12 form_left">
        <div className="mb-4">
          <label htmlFor="exampleFormControlInput1" className="form-label">DOB</label>
          <input 
          type="date" 
          className="form-control" 
          id="exampleFormControlInput1" 
          placeholder="DD/MM/YYYY" 
          name="dob" value={formState.dob} 
          onChange={handleChange}
          // {(e) =>  setDob(e.target.value)}
           />
        </div>
      </div>
      <div className="col-md-5 col-sm-12 form_right">
        <div className="mb-4">
          <label htmlFor="inputState" className="form-label">Gender</label>
          <select 
          id="inputState" 
          className="form-select form-control" 
          name="gender" value={formState.setGender} 
          onChange={handleChange}
          // {(e) => setGender(e.target.value)}
          >
            <option selected>Gender</option>
            <option>Male</option>
            <option>Female</option>
            <option>Other</option>
          </select>
        </div>
      </div>
    </div>

        <div className="row form_content">
          <div className="col-md-12 form_left">
            <div className="mb-4">
              <label htmlFor="resume" className="form-label">
                Resume
              </label>
              <input
                type="file"
                id="resume"
                name="resume"
                // value={formState.resume}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="row form_content">
          <div className="col-md-12 form_left">
            <div className="mb-4">
              <label htmlFor="coverLetter" className="form-label">
                Cover Letter
              </label>
              <textarea
                className="form-control"
                id="coverLetter"
                name="coverLetter"
                rows={4}
                value={formState.coverLetter}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div className="form_content text-center">
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ApplicationForm;
