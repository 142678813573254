import { useState } from "react";
import { MoveRight } from "lucide-react"; // Ensure you have this icon imported
import EnquiryForm from "./enquiry-form"; // Import the EnquiryForm component
import DelnieCloudAdmin from "../../../../../assets/images/recent_work/delnie_cloud_admin.png"; // Adjust path to your actual image location
import { Button } from "../../../ui/button";

const DelnieCloudAdminSection = (props:any) => {
  return (
    <>
      <div className="flex flex-col-reverse md:flex-row justify-between items-center px-6 md:px-20 py-10 mb-16 md:mb-20 gap-10">
        <div className="w-full md:w-1/2">
          <h1 className="font-lato font-regular text-2xl md:text-[64px] leading-tight md:leading-[64px] text-[#040404] mb-4 md:mb-6">
            Delnie Cloud Admin
          </h1>
          <p className="font-lato text-gray-700 text-base md:text-lg leading-relaxed mb-4">
            Delnie Cloud Admin is expertly designed to efficiently manage master
            data, providing centralized data management that enhances
            collaboration across all employees. With robust role-level
            restrictions, it ensures security and access control tailored to
            your organization's needs. The platform allows for seamless API
            creation, streamlining operations and integrating effortlessly with
            MongoDB Charts for real-time analytics and insightful
            visualizations. Experience the power of a fully customized admin
            panel, crafted to meet your unique business requirements, enabling
            easy analysis through intuitive Mongo charts and empowering your
            team with actionable insights.
          </p>
          <h4 className="font-lato font-semibold text-lg leading-6 mb-6">
            Get Delnie Cloud Admin free for 1 year.
          </h4>
          <Button
            className="bg-gradient-to-r from-blue-500 to-purple-600 text-white py-3 px-6 rounded-full font-semibold shadow-lg hover:from-purple-600 hover:to-blue-500 transition-all"
            // onClick={() => setIsModalOpen(true)}
            onClick={props.openEnquiryForm}

          >
            <span className="pr-4">Request free consultation</span>
            <MoveRight />
          </Button>
        </div>
        <div className="w-full md:w-1/2 mb-6 md:mb-0">
          <img
            src={DelnieCloudAdmin}
            alt="Delnie Cloud Admin"
            className="hidden md:block"
          />
        </div>
      </div>
    </>
  );
};

export default DelnieCloudAdminSection;
