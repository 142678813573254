import { Link } from "react-router-dom";
import "./index.css";
import Arrow from "../../../../assets/images/squad/arrow.png";
function Jobs(props: any) {
  let jobs = [
    {
      name: "UI/UX Designer I",
      department: "Creative",
      location: "Bengaluru/ Remote",
    },
    {
      name: "SDE I Android",
      department: "Engineering",
      location: "Bengaluru/ Remote",
    },
    {
      name: "SDE I IOS",
      department: "Engineering",
      location: "Bengaluru/ Remote",
    },
    {
      name: "SDE II Android",
      department: "Engineering",
      location: "Bengaluru/ Remote",
    },
    {
      name: "SDE II IOS",
      department: "Engineering",
      location: "Bengaluru/ Remote",
    },
  ];
  return (
    <div className="container-fluid squad_department">
      <div className="row vacancy">
        <div className="col-lg-4 col-sm-12">
          <div className="row department">
            <h1 className="bydepart">by department</h1>
          </div>
        </div>
        <div className="col-lg-8 col-sm-12 vac_list">
          {jobs.map((j: any) => (
            <div className="row job_vac">
              <Link to="">
                <div className="row vac_text">
                  <div className="col-10 vac_name">
                    <div className="row">
                      <h1 className="post_name">{j.name}</h1>
                    </div>
                    <div className="row">
                      <p className="location">{j.location}</p>
                    </div>
                  </div>
                  <div className="col-2 arrw">
                    <img src={Arrow} alt="arrow" width="15" height="24" />
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Jobs;
