import "./index.css";
import Contactbannner from "../../../../assets/images/contact/contactbannerimg.png";
import Contactbannnermobile from "../../../../assets/images/contact/contact_banner_mobile.png";

function ContactPage() {
  return (
    // <div className="container-fluid contact_page_banner">
    //   <img src={Contactbannner} alt="" className="img-fluid banner_image" />
    //   <div className="page-title banner_title centered">Contact Us</div>
    // </div>
    <div className="container-fluid contact_page_banner">
      <div className="img-container">
        <img
          src={Contactbannner}
          className="img-fluid contact_banner_desktop"
          alt=""
          width="100%"
          height="auto"
        ></img>
        <img
          src={Contactbannnermobile}
          className="img-fluid contact_banner_mobile"
          alt=""
          width="100%"
          height="auto"
        ></img>
        <div className="centered">
          <span className="page-title">Contact Us</span>
          {/* <span className="page-sub-title">We’d love to hear from you</span> */}
        </div>
      </div>
    </div>
  );
}
export default ContactPage;
