import One from "../../../../assets/images/squad/one.png";
import TWO from "../../../../assets/images/squad/squad_mobile_banner.png";
import "./index.css";
import SQUAD from "../../../../assets/images/squad/SQUAD.png";

function SquadBanner() {
  return (
    <div className="container-fluid enlist index-page-squad">
      <div className="row">
        <img
          src={One}
          alt="one"
          width="100%"
          height="auto"
          className="squad_banner_desktop"
        />

        <img
          src={TWO}
          alt="one"
          width="100%"
          height="auto"
          className="squad_banner_mobile"
        />
        <div className="enlist-title page-title">
          Enlist in the <span className="squad">SQUAD</span>
        </div>

        <img src={SQUAD} alt="squad" className="squad_img" />
      </div>
    </div>
  );
}
export default SquadBanner;
