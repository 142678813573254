import React from "react";

const TermsOfService = () => {
  return (
    <div
      className="terms-of-service-container"
      style={{ textAlign: "start", padding: "8rem" }}
    >
      <h1 style={{ textAlign: "center", padding: "8rem" }}>TERMS OF SERVICE</h1>

      <hr />

      <h2 style={{ color: "white" }}>OVERVIEW</h2>
      <p>
        This website is operated by Delnie Private Limited. Throughout the site,
        the terms “we”, “us” and “our” refer to Delnie Private Limited. Delnie
        Private Limited offers this website, including all information, tools,
        and services available from this site to you, the user, conditioned upon
        your acceptance of all terms, conditions, policies, and notices stated
        here.
      </p>

      <p>
        By visiting our site and/or purchasing something from us, including any
        subscription services for FavHiker, you engage in our “Service” and
        agree to be bound by the following terms and conditions (“Terms of
        Service”, “Terms”), including those additional terms and conditions and
        policies referenced herein and/or available by hyperlink. These Terms of
        Service apply to all users of the site, including without limitation
        users who are browsers, vendors, customers, merchants, and/or
        contributors of content.
      </p>

      <p>
        Please read these Terms of Service carefully before accessing or using
        our website. By accessing or using any part of the site, or by
        subscribing to FavHiker services, you agree to be bound by these Terms
        of Service. If you do not agree to all the terms and conditions of this
        agreement, then you may not access the website or use any services.
        Acceptance is expressly limited to these Terms of Service.
      </p>

      <p>
        Any new features or tools added to the current platform, including new
        subscription tiers for FavHiker, shall also be subject to the Terms of
        Service. We reserve the right to update, change or replace any part of
        these Terms of Service by posting updates and/or changes to our website.
        It is your responsibility to check this page periodically for changes.
        Your continued use of or access to the website following the posting of
        any changes constitutes acceptance of those changes.
      </p>

      <hr />

      <h2 style={{ color: "white" }}>ONLINE STORE & SUBSCRIPTION TERMS</h2>
      <p>
        By agreeing to these Terms of Service, you represent that you are at
        least the age of majority in your state or province of residence, or
        that you are the age of majority and have given us your consent to allow
        any of your minor dependents to use this site.
      </p>
      <p>
        You may not use our products or subscription services for any illegal or
        unauthorized purpose, nor may you, in the use of the Service, violate
        any laws in your jurisdiction (including but not limited to copyright
        laws).
      </p>
      <p>
        Subscriptions for FavHiker are subject to auto-renewal unless
        explicitly canceled. Charges will be processed according to the payment
        method chosen at the time of subscription.
      </p>
      <p>
        A breach or violation of any of the Terms will result in immediate
        termination of your Services, including any active FavHiker
        subscriptions.
      </p>

      <hr />

      <h2 style={{ color: "white" }}>GENERAL CONDITIONS</h2>
      <p>
        We reserve the right to refuse service to anyone for any reason at any
        time.
      </p>
      <p>
        You understand that your content (not including credit card
        information), may be transferred unencrypted and involve (a)
        transmissions over various networks; and (b) changes to conform and
        adapt to technical requirements of connecting networks or devices.
        Credit card information is always encrypted during transfer over
        networks.
      </p>
      <p>
        You agree not to reproduce, duplicate, copy, sell, resell or exploit any
        portion of the Service, use of the Service, or access to the Service or
        any contact on the website through which the service is provided,
        without express written permission by us.
      </p>

      <hr />

      <h2 style={{ color: "white" }}>ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</h2>
      <p>
        We are not responsible if information made available on this site is not
        accurate, complete, or current. The material on this site is provided
        for general information only and should not be relied upon or used as
        the sole basis for making decisions without consulting primary, more
        accurate, more complete, or more timely sources of information. Any
        reliance on the material on this site is at your own risk.
      </p>
      <p>
        This site may contain certain historical information. Historical
        information, necessarily, is not current and is provided for your
        reference only. We reserve the right to modify the contents of this site
        at any time, but we have no obligation to update any information on our
        site. You agree that it is your responsibility to monitor changes to our
        site.
      </p>

      <hr />

      <h2 style={{ color: "white" }}>MODIFICATIONS TO THE SERVICE AND PRICES</h2>
      <p>
        Prices for our products and FavHiker subscription plans are subject to
        change without notice.
      </p>
      <p>
        We reserve the right at any time to modify or discontinue the Service
        (or any part or content thereof) without notice at any time.
      </p>
      <p>
        We shall not be liable to you or to any third-party for any modification,
        price change, suspension, or discontinuance of the Service.
      </p>

      <hr />

      <h2 style={{ color: "white" }}>PRODUCTS OR SERVICES</h2>
      <p>
        Certain products, services, or subscription plans may be available
        exclusively online through the website. These products or services may
        have limited quantities and are subject to return or exchange only
        according to our Return Policy.
      </p>
      <p>
        We have made every effort to display as accurately as possible the
        colors and images of our products that appear at the store. We cannot
        guarantee that your computer monitor's display of any color will be
        accurate.
      </p>
      <p>
        We reserve the right to limit the sales of our products or subscription
        services to any person, geographic region, or jurisdiction on a
        case-by-case basis. We reserve the right to limit quantities of any
        products or services we offer, including subscription plans. All
        descriptions of products or product pricing are subject to change at any
        time without notice, at our sole discretion.
      </p>
      <p>
        We do not warrant that the quality of any products, services,
        information, or other material purchased or obtained by you will meet
        your expectations, or that any errors in the Service will be corrected.
      </p>
    </div>
  );
};

export default TermsOfService;
