import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import CloseIcon from '@mui/icons-material/Close';
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
// import mobilelogo from "../../../assets/images/navbar/logo.svg";
import mobilelogo from "../../../assets/images/header/logo1.svg";

import "./Sidebar.css";
import { Link, useNavigate } from "react-router-dom";

type Anchor = "left";

interface LeftDrawerProps {
  open: boolean;
  onClose: () => void;
  menuItems: { key: string; value: string; route: string; image: string }[];
}

const LeftDrawer: React.FC<LeftDrawerProps> = ({
  open,
  onClose,
  menuItems,
}) => {
  const navigate = useNavigate();

  const navigateTo = (path: string) => {
    navigate(path);
  };

  const list = () => (
    <Box
      sx={{
        width: "100%",
        maxWidth: "100%",
      }}
      role="presentation"
      onClick={onClose}
      onKeyDown={onClose}
    >
      <div
        className="d-flex justify-content-between align-items-center pr-5"
        style={{ backgroundColor: "#000" }}
      >
        <Button>
          <img src={mobilelogo} alt="" className="malayali-beer-logo-mobile" />
        </Button>

        <IconButton
          onClick={onClose}
          aria-label="close"
          sx={{
            padding: "8px",
            color: "white", // Adjust the padding as needed
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <Divider sx={{ bgcolor: "#363636" }} />
      <List>
        {menuItems.map((item) =>
          item.key === "Careers" || item.key === "Coolants" ? (
            <>
              <ListItem key={item.key} disablePadding>
                <ListItemButton component={Link} to={item.route}>
                  <ListItemText primary={item.value} />
                </ListItemButton>
              </ListItem>
              <Divider sx={{ bgcolor: "#363636" }} />
            </>
          ) : item.key === "Product Range" || item.key === "Contact Us" ? (
            <ListItem key={item.key} disablePadding>
              <ListItemButton sx={{ color: "#FF6D00" }}>
                <ListItemText primary={item.value} className="orange-text" />
              </ListItemButton>
            </ListItem>
          ) : item.key === "info@acceron.co" ? (
            <div className="d-flex justify-content-between align-items-center ms-3">
              <img src={item.image} alt="" />
              <ListItem key={item.key} disablePadding>
                <ListItemButton component={Link} to={item.route}>
                  <ListItemText
                    primary={item.value}
                    className="sidebar-contact-link"
                  />
                </ListItemButton>
              </ListItem>
            </div>
          ) : item.key === "8305096448" ? (
            <>
              <div className="d-flex justify-content-between align-items-center ms-3">
                <img src={item.image} alt="" />
                <ListItem key={item.key} disablePadding>
                  <ListItemButton component={Link} to={item.route}>
                    <ListItemText
                      primary={item.value}
                      className="sidebar-contact-link"
                    />
                  </ListItemButton>
                </ListItem>
              </div>
              <Divider sx={{ bgcolor: "#363636" }} />
            </>
          ) : (
            <ListItem key={item.key} disablePadding>
              <ListItemButton component={Link} to={item.route}>
                <ListItemText primary={item.value} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
      {/* <div className="sidebar-btn-container">
        <Button
          className="dealer-sidebar-btn"
          variant="outlined"
          onClick={(e: any) => navigateTo("/product_enquiry")}
        >
          <span>Product Enquiry</span>
        </Button>
        <Button
          className="contact-btn"
          variant="contained"
          onClick={(e: any) => navigateTo("/contact_us")}
        >
          <span>Contact Us</span>
        </Button>
      </div> */}
    </Box>
  );

  return (
    <Drawer anchor="left" open={open} onClose={onClose} className="left-drawer">
      {list()}
    </Drawer>
  );
};

export default LeftDrawer;
