import React from 'react';
import { Helmet } from 'react-helmet';
import { Widget } from '@typeform/embed-react';

const WebsiteDesignersEnquiry = () => {
  return (
    <div>
      <Helmet>
        <title>Find Expert Website Designers | Get Free Quotes Today | Delnie</title>
        <meta
          name="description"
          content="Need a new website? Get free quotes from expert website designers near you. Compare services, read reviews, and choose the perfect professional for your project. Fast, easy, and no obligation."
        />
        <meta
          name="keywords"
          content="website designers, free quotes, expert designers, web design services, hire web designers, compare services"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.delnie.com/website-design-enquiry" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#ffffff" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Find Expert Website Designers | Get Free Quotes Today | Delnie" />
        <meta
          property="og:description"
          content="Get free quotes from expert website designers near you. Compare services, read reviews, and choose the perfect professional."
        />
        <meta property="og:image" content="https://www.delnie.com/res/logo/logo.png" />
        <meta property="og:url" content="https://www.delnie.com/website-design-enquiry" />
        <meta property="og:type" content="website" />

        {/* Twitter Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Find Expert Website Designers | Get Free Quotes Today | Delnie" />
        <meta
          name="twitter:description"
          content="Get free quotes from expert website designers near you. Compare services, read reviews, and choose the perfect professional."
        />
        <meta name="twitter:image" content="https://www.delnie.com/res/logo/logo.png" />

        {/* Custom CSS to Hide Typeform Start Button */}
        {/* <style>{`
          .tf-v1-screen--center {
            display: none !important;
          }

          #typeform-root .welcome__button {
            display: none !important;
        }
        
        `}</style> */}

        {/* Schema Markup */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Delnie",
              "url": "https://www.delnie.com",
              "logo": "https://www.delnie.com/res/logo/logo.png",
              "description": "Get free quotes from expert website designers near you. Compare services, read reviews, and choose the perfect professional for your project.",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+91-9846280132",
                "contactType": "Business Enquiries",
                "availableLanguage": "English",
                "areaServed": "Global"
              }
            }
          `}
        </script>
      </Helmet>
      <Widget id="K1QcRpOq" style={{ width: '100%', height: '60vh' }} />
    </div>
  );
};

export default WebsiteDesignersEnquiry;
