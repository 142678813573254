import React from "react";

const PrivacyStatement = () => {
  return (
    <div
      className="privacy-statement"
      style={{ textAlign: "start", padding: "8rem" }}
    >
      <h1 style={{ textAlign: "center", padding: "8rem" }}>Privacy Policy</h1>

      <section>
        <h2 style={{ color: "white" }}>WHAT DO WE DO WITH YOUR INFORMATION?</h2>
        <p>
          When you purchase something from our store or subscribe to FavHiker services, 
          as part of the buying and subscription process, we collect personal information 
          such as your name, address, email address, and payment details.
        </p>
        <p>
          Additionally, when you browse our website, we automatically receive your computer’s 
          internet protocol (IP) address to help us understand your browser and operating system.
        </p>
        <p>
          With your permission, we may send you emails regarding new products, 
          subscription services, and other updates related to FavHiker or Delnie Private Limited.
        </p>
      </section>

      <section>
        <h2 style={{ color: "white" }}>CONSENT</h2>
        <h3 style={{ color: "white" }}>How do you get my consent?</h3>
        <p>
          When you provide us with personal information to complete a purchase, 
          verify your credit card, place an order, or subscribe to FavHiker services, 
          we imply your consent to our collecting and using it for that specific purpose only.
        </p>
        <p>
          If we request your personal information for a secondary purpose, such as marketing, 
          we will either ask you directly for your expressed consent or provide you with an opportunity to opt-out.
        </p>

        <h3 style={{ color: "white" }}>How do I withdraw my consent?</h3>
        <p>
          If after opting in, you change your mind, you can withdraw your consent 
          for us to contact you, or for the continued collection, use, or disclosure 
          of your information, by contacting us at christo.john@delnie.com or 
          by mailing us at the address provided below.
        </p>
      </section>

      <section>
        <h2 style={{ color: "white" }}>DISCLOSURE</h2>
        <p>
          We may disclose your personal information if we are required by law to do so 
          or if you violate our Terms of Service.
        </p>
      </section>

      <section>
        <h2 style={{ color: "white" }}>PAYMENT</h2>
        <p>
          We use Razorpay for processing payments related to purchases or FavHiker subscription services. 
          Neither we nor Razorpay store your card data on our servers. Your payment information 
          is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) 
          during processing, and only retained for as long as necessary to complete your transaction.
        </p>
        <p>
          Our payment gateway adheres to PCI-DSS standards as managed by the PCI Security Standards Council, 
          which is a collaborative effort of major brands such as Visa, MasterCard, American Express, and Discover.
        </p>
        <p>
          For more information, you can review the terms and privacy policy of Razorpay at{" "}
          <a
            href="https://razorpay.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://razorpay.com
          </a>.
        </p>
      </section>

      <section>
        <h2 style={{ color: "white" }}>THIRD-PARTY SERVICES</h2>
        <p>
          In general, the third-party providers used by us will only collect, 
          use, and disclose your information as necessary to perform the services they provide. 
          However, certain third-party service providers, such as payment gateways, 
          have their own privacy policies regarding the information we must provide for your transactions.
        </p>
        <p>
          We recommend reviewing their privacy policies to understand how your personal 
          information is handled by these providers. Once you leave our website 
          or are redirected to a third-party site, this Privacy Policy and our Terms of Service no longer apply.
        </p>
      </section>

      <section>
        <h2 style={{ color: "white" }}>SECURITY</h2>
        <p>
          To protect your personal information, we take reasonable precautions 
          and follow industry best practices to ensure it is not inappropriately lost, 
          misused, accessed, disclosed, altered, or destroyed.
        </p>
      </section>

      <section>
        <h2 style={{ color: "white" }}>COOKIES</h2>
        <p>
          We use cookies to maintain the session of your user. Cookies are used 
          to enhance user experience and are not employed to personally identify you 
          on external websites.
        </p>
      </section>

      <section>
        <h2 style={{ color: "white" }}>AGE OF CONSENT</h2>
        <p>
          By using this site, you represent that you are at least the age of majority 
          in your state or province of residence, or that you are the age of majority 
          and have given us your consent to allow any of your minor dependents to use this site.
        </p>
      </section>

      <section>
        <h2 style={{ color: "white" }}>CHANGES TO THIS PRIVACY POLICY</h2>
        <p>
          We reserve the right to modify this privacy policy at any time. Changes 
          and clarifications will take effect immediately upon posting on the website. 
          If significant changes are made, we will notify you here so you are aware 
          of what information we collect, how we use it, and under what circumstances 
          we may disclose it.
        </p>
        <p>
          If our company or FavHiker is acquired or merged, your information may be 
          transferred to the new owners to ensure continuity of service.
        </p>
      </section>

      <section>
        <h2 style={{ color: "white" }}>QUESTIONS AND CONTACT INFORMATION</h2>
        <p>
          If you would like to: access, correct, amend, or delete any personal 
          information we have about you, or if you have concerns, contact our 
          Privacy Compliance Officer at christo.john@delnie.com or by mail at: <br/>
          Christo John <br/>
          Delnie Private Limited, <br/>
          Raheja Platinum, Marol CHS Rd, Andheri - Kurla Road, <br/>
          Marol, Andheri East, Mumbai, <br/>
          Maharashtra 400059 <br/>
          CIN - U74999MH2017PTC300138 <br/>
        </p>
      </section>
    </div>
  );
};

export default PrivacyStatement;
