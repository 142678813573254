import "./index.css";
import favhikerimages from "../../../../assets/images/products/favhiker.gif";
import FavhikerSectionImg from "../../../../assets/images/home/favhiker-section-img.png";

function HomeFavhiker() {
  return (
    <div className="container-fluid home_favhiker_page">
      <div className="row our_products_heading">
        <h1>Our Products</h1>
      </div>

      <div className="row favhiker_heading favhiker_details_row justify-between items-center">
        <div className="col-lg-6 col-sm-12">
          <h1 className="mb-8">
            <span>F</span> Favhiker
          </h1>

          <div className="row favhiker_content_header">
            <h3>Taste Connect and Award</h3>
            <h4>Your Social Gateway to the Culinary World</h4>
            <p>
              Your personalised culinary companion, reshaping city dining.
              Discover curated hidden gems, tailored to your unique taste.
              Immerse in savouring local flavours—a delicious adventure awaits.
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-sm-12">
          <img src={FavhikerSectionImg} alt="" className="img-fluid " />
        </div>
      </div>
      <div className="row website_link_row">
        <a
          href="https://www.favhiker.com/#home"
          target="_blank"
          rel="noopener noreferrer"
          className="favhiker_website_link flex items-center gap-2"
        >
          Visit Website{" "}
         
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="29"
            viewBox="0 0 30 29"
            fill="none"
            style={{ display: "inline" }}  // Use an object for the style prop
          >
            <path
              d="M29.0361 1.99953C29.0361 1.1212 28.3241 0.40917 27.4458 0.40917L13.1325 0.409169C12.2542 0.409169 11.5422 1.1212 11.5422 1.99953C11.5422 2.87786 12.2542 3.58989 13.1325 3.58989L25.8554 3.58989L25.8554 16.3128C25.8554 17.1911 26.5674 17.9031 27.4458 17.9031C28.3241 17.9031 29.0361 17.1911 29.0361 16.3128L29.0361 1.99953ZM3.12456 28.5699L28.5703 3.12409L26.3212 0.874975L0.875445 26.3208L3.12456 28.5699Z"
              fill="white"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}

export default HomeFavhiker;
