import { useEffect } from "react";
import SEO from "../../common/SEO";
import ContactUsForm from "./contact-form";
import ContactPage from "./contact-page";
import "./index.css";

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <SEO
        title="Contact Us"
        description="If you have any questions, need assistance, or want to learn more about our services, feel free to reach out to us."
        keywords="questions, assistance, learn, services"
        image=""
        url="https://whttps://www.delnie.com/contact_usww.famycare.com.au/"
      /> */}

      <div className="container-fluid contact_page">
        <div>
          <ContactPage />
        </div>
        <div>
          <ContactUsForm />
        </div>
      </div>
    </>
  );
}
export default ContactUs;
