import "./index.css";
function HomeOurServices() {
  return (
    <div className="container-fluid what_we_provide_page">
      <div className="row wwp_heading_row">
        <h1>Our services</h1>
      </div>
      <div className="row wwp_para_row">
        <p>
          At Delnie, product development forms a cornerstone of our business
          technology practice. Today, our primary focus is to provide a platform
          for exploring innovative and unique ideas and developing them into
          efficient products. We are a step ahead in understanding and analysing
          the market and providing the right solutions and strategies.At Delnie,
          product development forms a cornerstone of our business technology
          practice. Today, our primary focus is to provide a platform for
          exploring innovative and unique ideas and developing them into
          efficient products. We are a step ahead in understanding and analysing
          the market and providing the right solutions and strategies.
        </p>
      </div>
      <div className="row wwp_service_row">
        <div className="col-lg-4 col-sm-6 wwp_service_col">
          <h1>Mobile Apps</h1>
          <p>(iOS/Android)</p>
        </div>
        <div className="col-lg-3 col-sm-6 wwp_service_col wwp_service_col_3">
          <h1>Websites</h1>
          <p>(Mobile/Desktop)</p>
        </div>
        <div className="col-lg-4 col-sm-12 wwp_service_col">
          <h1>Web applications</h1>
          <p>(Custom React Admin panels and others)</p>
        </div>
      </div>
    </div>
  );
}
export default HomeOurServices;
