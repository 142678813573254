import "./product-folio.css";
import billd_img from "../../../../assets/images/products/billd.png";
import launching from "../../../../assets/images/products/launching_soon.svg";
import Clock from "../../../../assets/images/why-delnie/clock.svg";
import Hand from "../../../../assets/images/why-delnie/hand.svg";
import Screw from "../../../../assets/images/why-delnie/screw.svg";

function ProductsPortfolio() {
  return (
    <div className="container-fluid services">
      <div className="row tailor">
        <div className="col-12 made">
          <div className="row solutions">
            <div className="col-4 tailor_image">
              <div className="row ph">
                <div className="row hp">
                  <img
                    src={Clock}
                    width="80px"
                    height="80px"
                    className="center"
                  ></img>
                </div>
              </div>
            </div>
            <div className="col-8 tailor_caption">
              <div className="row tailor_writing">
                <h1 className="tailor_head">Tailor made Solutions</h1>
                <p className="tailor_para section-content">
                  We understand every business is different and hence every
                  business needs a software support that address their set of
                  unique requirements. Thats were we at Delnie innovation labs
                  create tailor made software solutions for business to provide
                  maximum efficiency, optimization and scalablity.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row regular_updates">
        <div className="col-12 reg_content">
          <div className="row reg_solutions">
            <div className="col-4 reg_photo">
              <div className="row ph">
                <div className="row hp">
                  <img
                    src={Hand}
                    width="80px"
                    height="80px"
                    className="center"
                  ></img>
                </div>
              </div>
            </div>
            <div className="col-8 reg_caption">
              <div className="row reg_writing">
                <h1 className="reg_head">Regular Upgrades</h1>
                <p className="reg_para section-content">
                  As our Client’s business evolve and scale, we upgrade our
                  software support as well to address the additional set of
                  requirements, constanly improvise the user experinence and
                  make sure the business run smoothly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 support">
        <div className="row support_content">
          <div className="col-4 sup_photo">
            <div className="row ph">
              <div className="row hp">
                <img src={Screw} height="80px" className="center"></img>
              </div>
            </div>
          </div>
          <div className="col-8 sup_caption">
            <div className="row sup_writing">
              <h1 className="sup_head">24/7 Support</h1>
              <p className="sup_para section-content">
                We at Delnie understand the importance of running the business
                without a hinderance and role of IT support in that functioning.
                We understand no system is 100% perfect and thus we commit to
                real-time 24/7 support to our clients to make sure there is
                never a down time that impacts the business.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductsPortfolio;
