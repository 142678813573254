import React, { useState } from "react";
import ProductDelnieCloudAdmin from "../cloud-admin-content";
import ProductFavhiker from "../favhiker-tab-content";
import "./index.css";
interface TabProps {
  label: string;
  content: React.ReactNode;
}

const Tab: React.FC<TabProps> = ({ content }) => {
  return <div>{content}</div>;
};

function ProductTabs() {
  const [activeTab, setActiveTab] = useState<number>(0);

  const tabs: TabProps[] = [
    {
      label: "Favhiker",
      content: <ProductFavhiker />,
    },
    {
      label: "Delnie Cloud Admin",
      content: <ProductDelnieCloudAdmin />,
    },
  ];

  return (
    <div className="container-fluid products_tab_section">
      <div className="row our_products_heading_row">
        <h1>Our Products</h1>
      </div>
      <div className="row tab-header">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(index)}
            className={index === activeTab ? "active" : ""}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="tab-content">
        <Tab content={tabs[activeTab].content} label={""} />
      </div>
    </div>
  );
}

export default ProductTabs;
