import "./index.css";
import cloudadminimage from "../../../../assets/images/products/cloud_admin_image.png";
import { Link } from "react-router-dom";
function HomeCloudAdmin() {

  const keyFeatures = [
    "User-Friendly Interface",
    "Customizable Dashboards",
    "Advanced Data Analytics",
    "Role-Based Access Control",
    "Automated Workflows",
    "Real-Time Monitoring",
    "Integration Capabilities",
    "Scalability",
    "24/7 Support"
];

const SvgIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none" className="icon">
        <circle cx="10.6409" cy="11.5784" r="10.6409" fill="white" fillOpacity="0.6" />
    </svg>
);
  return (
    <div className="container-fluid home_cloud_admin_page">
      <div className="row our_products_heading">
        <h1>Delnie Cloud Admin</h1>
      </div>
      <div className="row cloud_products_details">
      <div className="col-lg-6 col-sm-12 cloud_products_details_col">
        <div className="row cloud_admin_header">
            <h1>Transforming Data Management with Customized Solutions</h1>
            <h2>Key Features</h2>
            {keyFeatures.map((feature, index) => (
                <p key={index}>
                    <SvgIcon /> {feature}
                </p>
            ))}
        </div>
    </div>
        <div className="col-lg-6 com-sm-12 cloud_products_image_col">
          <img src={cloudadminimage} alt="" className="img-fluid" />
        </div>
      </div>
      <div className="row enquiry_link_row">
        <a
          href="/contact_us"
          rel="noopener noreferrer"
          className="favhiker_website_link"
        >
          Enquire Now{" "}
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="29"
            viewBox="0 0 30 29"
            fill="none"
          > */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="29"
            viewBox="0 0 30 29"
            fill="none"
            style={{ display: "inline" }}  // Use an object for the style prop
          >
            <path
              d="M29.0361 1.99953C29.0361 1.1212 28.3241 0.40917 27.4458 0.40917L13.1325 0.409169C12.2542 0.409169 11.5422 1.1212 11.5422 1.99953C11.5422 2.87786 12.2542 3.58989 13.1325 3.58989L25.8554 3.58989L25.8554 16.3128C25.8554 17.1911 26.5674 17.9031 27.4458 17.9031C28.3241 17.9031 29.0361 17.1911 29.0361 16.3128L29.0361 1.99953ZM3.12456 28.5699L28.5703 3.12409L26.3212 0.874975L0.875445 26.3208L3.12456 28.5699Z"
              fill="white"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}
export default HomeCloudAdmin;
