import React from 'react';

interface SnackbarProps {
  message: string;
  isOpen: boolean;
  onClose: () => void;
}

const Snackbar: React.FC<SnackbarProps> = ({ message, isOpen, onClose }) => {
  console.log("Snackbar - message:", message, "isOpen:", isOpen);

  return (
    <div
      style={{
        visibility: isOpen ? 'visible' : 'hidden',
        backgroundColor: '#4CAF50', // Green background
        color: '#fff',
        padding: '16px',
        position: 'fixed',
        bottom: '20px', // Bottom of the screen (more typical for snackbars)
        left: '50%',
        transform: 'translate(-50%, 0)', // Center horizontally
        borderRadius: '8px', // More rounded corners
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', // Shadow for depth
        transition: 'opacity 0.3s ease, visibility 0.3s ease 0.3s', // Smooth fade-out
        opacity: isOpen ? 1 : 0,
        zIndex: 1000, // Ensure it appears above other elements
        display: 'flex', // Use flexbox for alignment
        alignItems: 'center', // Center items vertically
        justifyContent: 'space-between', // Space between message and close button
      }}
      role="alert" // Accessibility
      aria-live="assertive" // Accessibility for screen readers
    >
      {/* Message */}
      <span style={{ marginRight: '10px' }}>{message}</span>
      {/* Close icon positioned at the end */}
      <div
        style={{
          cursor: 'pointer',
          fontSize: '24px', // Size of the close button
          marginLeft: '10px',
          fontWeight: 'bold',
        }}
        onClick={onClose}
        aria-label="Close Snackbar" // Accessibility for close button
      >
        &times; {/* This is the standard close icon */}
      </div>
    </div>
  );
};

export default Snackbar;
