import * as React from "react";
import "./Navbar.css";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import HamburgerIcon from "../../assets/images/Malayali/Navbar/hamburger.png";
import logo from "../../../assets/images/header/logo1.svg";
import mobilelogo from "../../../assets/images/header/delnie_logo_black.png";

// import mobilelogo from "../../../assets/images/navbar/logo.svg";

import CustomizedMenus from "../Dropdown/Dropdown";
import Dropdown from "../Dropdown/Dropdown";
import DropdownIcon from "../Dropdown/DropdownIcon";

import { useContext, useEffect, useState } from "react";
import MenuIcon from "../../../assets/images/navbar/menu-icon.png";
import { Link, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";



const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const profileData = [
  {
    key: "Home",
    value: "Home",
    route: "/",
  },
  {
    key: "About Us",
    value: "About Us",
    route: "/about-us",
  },
  {
    key: "Our Products",
    value: "Our Products",
    route: "/our-products",
  },
  {
    key: "The Squad",
    value: "The Squad",
    route: "/squad",
  },
  {
    key: "Contact",
    value: "Contact Us",
    route: "/contact_us",
  },
  // {
  //   key: "Product Range",
  //   value: "Product Range",
  //   // route: "/find_dealer",
  // },
  // {
  //   key: "Engine Oils",
  //   value: "Engine Oils",
  //   // route: "/find_dealer",
  // },
  // {
  //   key: "Greases",
  //   value: "Greases",
  //   // route: "/find_dealer",
  // },
  // {
  //   key: "Gear Oils",
  //   value: "Gear Oils",
  //   // route: "/find_dealer",
  // },
  // {
  //   key: "Coolants",
  //   value: "Coolants",
  //   // route: "/find_dealer",
  // },
  // {
  //   key: "Contact Us",
  //   value: "Contact Us",
  //   // route: "/find_dealer",
  // },
];

const handleFieldClick = (e: any) => {
  console.log(e.target.value);
};

export default function Navbar(props: any) {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState('home');

  useEffect(() => {
    // fetchProfile();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleHome = () => {
    navigate("/");
  };

  const handleAbout = () => {
    setActiveButton('about')
    navigate("/about-us");
  };

  const handleProducts = () => {
    setActiveButton('products')
    navigate("/our-products");
  };

  const handleOurServices = () => {
    navigate("/our-services");
  };

  const handleCareers = () => {
    setActiveButton('careers');
    navigate("/squad");
  };

  const handleDealer = () => {
    navigate("/product_enquiry");
  };

  const handleContact = () => {
    setActiveButton('contact')
    navigate("/contact_us");
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const handleScreen = () => {
    // document.querySelector("body").style.overflow = "auto";
  };
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          {/* <Badge badgeContent={17} color="error"> */}

          {/* </Badge> */}
        </IconButton>
        <p className="para-margin">Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        ></IconButton>
        <p className="para-margin">Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="navbar-header">
        <Toolbar className="navbar-height">
          <div className="d-flex d-md-none w-100 justify-content-between align-items-center">
            <Button component={Link} to="/home">
              <img src={mobilelogo} alt="" className="h-12" />
            </Button>

            <Dropdown
              // name={state?.user?.first_name}
              // name={props.user.first_name}
              menuData={profileData}
              imagePath={MenuIcon}
              color="transparent"
              className="pro-btn-main"
              buttonClass="pro-nav-btn pro-acc-btn navbar-mobile-btn"
              menuClass="menu-navbar"
              // endIcon={<DropdownIcon />}
              // endIcon={<DropdownIcon iconColor="white" />}
              spanclass={"span-spacing"}
              customText="Logout"
              // customFunction={handleLogout}
              // profileFunction={handleProfile}
              handleFieldClick={handleFieldClick}
            />
          </div>

          <div className="d-md-flex d-none w-100 justify-content-between align-items-center">
            <Button component={Link} to="/home">
              <img src={logo} alt="" />
            </Button>
            <div className="d-flex justify-content-center align-items-center navbar-btn-container">
              {/* <Button className="navbar-btns" onClick={handleHome}>
                <h4>Home</h4>
              </Button> */}
              <Button component={Link} to="/home" className={activeButton === 'home' ? 'navbar-btns active' : 'navbar-btns'} onClick={() => setActiveButton('home')}>
                <h4>Home</h4>
              </Button>
              <Button className={activeButton === 'about' ? 'navbar-btns active' : 'navbar-btns'} onClick={() => handleAbout()}>
                <h4>About Us</h4>
              </Button>
              <Button className={activeButton === 'products' ? 'navbar-btns active' : 'navbar-btns'} onClick={() => handleProducts()}>
                <h4>Our Products</h4>
              </Button>
              <Button className={activeButton === 'careers' ? 'navbar-btns active' : 'navbar-btns'} onClick={() => handleCareers()}>
                <h4>The Squad</h4>
              </Button>
              <Button className={activeButton === 'contact' ? 'navbar-btns active' : 'navbar-btns'} onClick={() => handleContact()}>
                <h4>Contact Us</h4>
              </Button>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
