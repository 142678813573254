import "./index.css";
function HomeAboutPage() {
  return (
    <div className="container-fluid home_about_page">
      <div className="row home_about_row">
        <h1>About Delnie</h1>
      </div>
      <div className="row home_about_row">
        <p>
          Since its inception in 2017, Delnie has been at the forefront of
          innovation in the IT industry, driven by a commitment to delivering
          cutting-edge solutions. Over the years, we have cultivated strong,
          trusted partnerships with our clients by providing tailored business
          solutions and customized products that address their unique needs.
          Today, Delnie operates through two specialized divisions: Delnie
          Innovation Lab, which focuses on developing groundbreaking products,
          and Delnie Consultancy Service, dedicated to offering premium IT
          services by optimizing and integrating the best solutions available in
          the market.
        </p>
      </div>
      <div className="row home_learn_more_row">
        <a href="/about-us" className="about_us_link">
          Learn More {""}
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="29"
            viewBox="0 0 30 29"
            fill="none"
          > */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="29"
            viewBox="0 0 30 29"
            fill="none"
            style={{ display: "inline" }}  // Use an object for the style prop
          >
            {/* SVG content here */}

          <path
            d="M29.0361 1.99953C29.0361 1.1212 28.3241 0.40917 27.4458 0.40917L13.1325 0.409169C12.2542 0.409169 11.5422 1.1212 11.5422 1.99953C11.5422 2.87786 12.2542 3.58989 13.1325 3.58989L25.8554 3.58989L25.8554 16.3128C25.8554 17.1911 26.5674 17.9031 27.4458 17.9031C28.3241 17.9031 29.0361 17.1911 29.0361 16.3128L29.0361 1.99953ZM3.12456 28.5699L28.5703 3.12409L26.3212 0.874975L0.875445 26.3208L3.12456 28.5699Z"
            fill="#CCCCCC"
          />
        </svg>
      </a>
    </div>
    </div >
  );
}
export default HomeAboutPage;
