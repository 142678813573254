import DelnieMobileLogo from "../../../../../assets/images/recent_work/delnie_logo_landscape.png";
import PhoneCallIcon from "../../../../../assets/images/website-service/phone_call.svg";
import WhatsappIcon from "../../../../../assets/images/website-service/whatsapp_icon.svg";
import { Button } from "../../../ui/button";

export function ServiceNavbar(props: any) {
  return (
    <div className="flex justify-between items-center py-4 px-6 md:px-20 md:py-6 bg-white shadow-sm">
      <img
        src={DelnieMobileLogo}
        alt="Delnie Logo"
        className="h-12 md:h-16 object-contain"
      />
      <div className="hidden sm:flex space-x-4">
        <Button
          className="flex items-center justify-center h-12 px-6 bg-gradient-to-r from-blue-600 to-purple-600 hover:from-purple-600 hover:to-blue-600 transition duration-300 ease-in-out shadow-lg focus:outline-none focus:ring-2 focus:ring-purple-600 text-white rounded-full"
          onClick={() => props.handlePhoneCall("9846280132")}
        >
          <img src={PhoneCallIcon} alt="Phone Icon" className="w-5 h-5 mr-2" />
          <span className="font-sans font-semibold text-sm">Call us</span>
        </Button>
        <Button
          className="flex items-center justify-center h-12 px-6 bg-green-600 text-white rounded-full transition hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-600"
          onClick={() => props.handleWhatsApp("9846280132")}
        >
          <img
            src={WhatsappIcon}
            alt="WhatsApp Icon"
            className="w-5 h-5 mr-2"
          />
          <span className="font-sans font-semibold text-sm">WhatsApp Us</span>
        </Button>
      </div>
    </div>
  );
}
