import "./index.css";
import Productimageone from "../../../../assets/images/products/products_banner_favhiker.png";
import Productimagetwo from "../../../../assets/images/products/products_banner_cloud.png";
import OurProduct from "../../../../assets/images/products/Our_product.png"
import OurProductMobile from "../../../../assets/images/products/Our_product_mobile.png"

function OurProducts() {
  return (
    <div className="container-fluid our_products_page index-page-products">
      <div className="row products_image_row">
        <div className="col-lg-12">
          <img src={OurProduct} alt="" className="img-fluid desktop_product" />
          <img src={OurProductMobile} alt="" className="img-fluid mobile_product" />
        {/* </div>
        <div className="col-lg-6">
          <img src={Productimagetwo} alt="" className="img-fluid" /> */}
        </div>
      </div>
      {/* <div className="row products_page_header_row">
        <h1>Choose from existing suite of products</h1>
      </div> */}
    </div>
  );
}
export default OurProducts;
