import React, { useState } from "react";
import quotation_img from "../../../../../assets/images/recent_work/quotqtion_img.png";
import gate_logo from "../../../../../assets/images/recent_work/Gate sq logo.png";
import famycare_logo from "../../../../../assets/images/recent_work/famycare square logo.png";
import acceron_logo from "../../../../../assets/images/recent_work/acceron sq logo.png";

interface Testimonial {
  content: string;
  author: string;
  sub_title: string;
  logo: string;
}

const TestimonialCard: React.FC<{ testimonial: Testimonial }> = ({
  testimonial,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="bg-white bg-gradient-to-r from-gray-50 to-white shadow-lg rounded-2xl p-6 md:p-8 relative">
      <img
        src={quotation_img}
        alt="double quote"
        className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10 h-10"
      />
      <div className="mb-3">
        <p
          className={`text-base md:text-lg font-roboto text-gray-700 mb-4 leading-relaxed ${isExpanded ? "max-h-full" : "max-h-32 overflow-hidden"}`}
          dangerouslySetInnerHTML={{ __html: testimonial.content }}
        />
        <button
          onClick={toggleExpand}
          className="text-blue-500 font-bold hover:underline"
        >
          {isExpanded ? "Read Less" : "Read More"}
        </button>
      </div>
      <div className="text-left hidden md:block">
        <div className="flex items-center justify-center">
          <div className="w-1/6 text-center flex items-center justify-center">
            <img
              src={testimonial.logo}
              alt="logo"
              className="w-[30px] h-[30px]"
            />
          </div>
          <div className="w-full text-center flex ">
            <div>
              <h2 className="text-left font-[16px] font-bold text-black font-lato">
                {testimonial.author}
              </h2>
              <p className="mt-2 font-[12px] font-bold text-black font-lato">
                {testimonial.sub_title}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <div className="flex flex-col items-start">
          <div className="w-full flex items-center mb-4">
            <img
              src={testimonial.logo}
              alt="logo"
              className="w-[30px] h-[30px] mr-2"
            />
            <div>
              <h2 className="text-left text-[16px] font-bold text-black">
                {testimonial.author}
              </h2>
              <p className="mt-1 text-[12px] font-bold text-black">
                {testimonial.sub_title}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ClientTestimonials: React.FC = () => {
  const testimonials: Testimonial[] = [
    {
        content:
            "We are extremely satisfied with the work Delnie did for <strong>GATE Industrial Solutions</strong>. The SEO strategy they implemented has significantly increased our organic leads. As a supplier based in Saudi Arabia, it's crucial for us to reach the right audience, and Delnie’s expertise in SEO has made a big impact on our business.<br><br>The website design is professional, easy to navigate, and perfectly tailored to showcase our industrial products and services. Their team was quick to implement changes and provided consistent support throughout the project. Delnie truly understands how to help businesses grow and scale online.",
        author: "GATE",
        sub_title: "Industrial Product Supplier",
        logo: gate_logo,
    },
    {
        content:
            "Working with Delnie on our web app for <strong>Acceron Industries</strong> has been an exceptional experience. The team is passionate about constantly upgrading the UI, offering creative suggestions that have enhanced the user experience. Their innovative approach and attention to detail are truly impressive. <br><br>Additionally, <strong>Delnie Cloud Admin</strong> has been a game-changer in efficiently managing our data, making our operations smoother and more organized. We are delighted with the collaboration and highly recommend Delnie for their expertise and dedication.",
        author: "Acceron",
        sub_title: "Automotive Lubrication Solution Manufacturer",
        logo: acceron_logo,
    },
    {
      content:
          "Our experience with Delnie in building the website for <strong>Famycare</strong> has been outstanding. A special mention goes to <strong>Rajesh</strong> for his exceptional coordination and for quickly implementing live changes in Figma, which greatly streamlined the process. The team's prompt responses on WhatsApp made us feel as though we were collaborating with an extension of our own team.<br><br>As a new business, we greatly value their insightful vision for scaling our operations. Delnie's expertise and commitment to our success have been invaluable. We wholeheartedly recommend their services!",
      author: "Famycare",
      sub_title: "NDIS Provider, Melbourne",
      logo: famycare_logo,
  }
];


  return (
    <div className="py-16 px-6 md:px-20 bg-gradient-to-b from-white via-gray-100 to-gray-200">
      <h2 className="text-center font-lato font-extrabold text-3xl md:text-5xl leading-tight text-gray-900 mb-12 md:mb-16">
        What Our Clients Say
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {testimonials.map((testimonial, idx) => (
          <TestimonialCard key={idx} testimonial={testimonial} />
        ))}
      </div>
    </div>
  );
};

export default ClientTestimonials;
