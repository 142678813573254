interface Question {
  id: number;
  label: string;
  type: "text" | "textarea" | "select" | "number" | "tel" | "date";
  name: string;
  multiple?: boolean;
  options?: { label: string; image?: string }[];
}

 const questions: Question[] = [
  {
    id: 1,
    label: "What are you looking to achieve with your website project?",
    type: "select",
    name: "projectType",
    options: [
      { label: "Launch a new website" },
      { label: "Redesign an existing website" },
    ],
  },
  {
    id: 2,
    label: "What is the primary purpose of your website?",
    type: "select",
    name: "websitePurpose",
    options: [
      { label: "Promote my business or services" },
      { label: "Sell products or services (e-commerce)" },
      { label: "Provide information or resources" },
      { label: "Other (e.g., personal blog, portfolio)" },
    ],
  },
  {
    id: 3,
    label: "What design style best reflects your brand?",
    type: "select",
    name: "designStyle",
    options: [
      { label: "Modern and minimalist" },
      { label: "Elegant and luxurious" },
      { label: "Bold and vibrant" },
      { label: "Professional and corporate" },
      { label: "Other (please specify)" },
    ],
  },
  {
    id: 4,
    label: "What are your website needs?",
    type: "select",
    name: "websiteNeeds",
    options: [
      { label: "To advertise my business/services" },
      { label: "To sell products/services (e-commerce)" },
      { label: "For educational purposes" },
      { label: "Other" },
    ],
  },
  {
    id: 5,
    label: "What features do you need for your website?",
    type: "select",
    multiple: true,  // Allows multiple selections
    name: "websiteFeatures",
    options: [
      { label: "Blogs" },
      { label: "eCommerce - sell products/services" },
      { label: "Live chat" },
      { label: "Media and document hosting" },
      { label: "Social media integration" },
      { label: "User self-management (e.g., user accounts)" },
      { label: "Payment gateway" },
      { label: "Other" },
    ],
  },
  {
    id: 6,
    label: "Would you like assistance with branding or logo design?",
    type: "select",
    name: "brandingAssistance",
    options: [
      { label: "Yes, I need logo and branding services" },
      { label: "No, I already have logo and branding materials" },
    ],
  },
  {
    id: 7,
    label: "What is your approximate budget for this project?",
    type: "select",
    name: "budgetRange",
    options: [
      { label: "Less than ₹25,000" },
      { label: "₹25,000 - ₹49,999" },
      { label: "₹50,000 - ₹99,999" },
      { label: "₹1,00,000 - ₹1,99,999" },
      { label: "₹2,00,000 - ₹2,99,999" },
      { label: "₹3,00,000 - ₹4,99,999" },
      { label: "₹5,00,000 or more" },
      { label: "I’d like to discuss this with the professional" },
    ],
  },
  {
    id: 8,
    label: "What type of business is this for?",
    type: "select",
    name: "businessType",
    options: [
      { label: "Personal project" },
      { label: "Proprietor/self-employed" },
      { label: "Small business (1 - 9 employees)" },
      { label: "Medium business (10 - 29 employees)" },
      { label: "Large business (30 - 99 employees)" },
      { label: "Extra-large business (100 or more employees)" },
      { label: "Charity/non-profit" },
      { label: "Other" },
    ],
  },
  {
    id: 9,
    label: "What industry does your business belong to?",
    type: "select",
    name: "industry",
    options: [
      { label: "Business services" },
      { label: "Creative industries" },
      { label: "Entertainment & events" },
      { label: "Financial services" },
      { label: "Health & fitness" },
      { label: "Home services" },
      { label: "Restaurant/food" },
      { label: "Other" },
    ],
  },
  {
    id: 10,
    label: "When would you like the website to go live or be updated?",
    type: "select",
    name: "timeline",
    options: [
      { label: "ASAP" },
      { label: "Within a few weeks" },
      { label: "Within a month" },
      { label: "Within a few months" },
      { label: "I would like to discuss this with the professional" },
    ],
  },
  {
    id: 11,
    label: "How likely are you to make a hiring decision soon?",
    type: "select",
    name: "hiringIntent",
    options: [
      { label: "I'm ready to hire now" },
      { label: "I'm definitely going to hire someone" },
      { label: "I'm likely to hire someone" },
      { label: "I will possibly hire someone" },
      { label: "I'm planning and researching" },
    ],
  },
  {
    id: 12,
    label: "Please provide your Name for further communication.",
    type: "text",
    name: "name",
  },
  {
    id: 13,
    label: "Please provide your phone number for further communication.",
    type: "tel",
    name: "phone_number",
  },
  {
    id: 14,
    label:
      "Lastly, could you share your email address to receive our response?",
    type: "text",
    name: "email",
  },
];
export default questions;
