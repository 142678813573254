import "./squad.css";

import WhyJoinUs from "./why-join-us";
import Jobs from "./jobs";

import { useEffect } from "react";
import SquadBanner from "./squad-banner";
import SEO from "../../common/SEO";

function Squad() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <SEO
        title="Squad - Delnie"
        description="An Opportunity to Explore the Real World Challenges"
        keywords="lOpportunity, Explore, Real World, Challenges"
        image="../../assets/images/hero_section_bg.png"
        url="https://www.delnie.com/squad"
      /> */}

      <SquadBanner />
      <WhyJoinUs />
      <Jobs />
    </>
  );
}
export default Squad;
