import { Link } from "react-router-dom";
import manone from "../../../../assets/images/index/manone.svg";
import mantwo from "../../../../assets/images/index/mantwo.png";
import manthree from "../../../../assets/images/index/manthree.png";
import manfour from "../../../../assets/images/index/manfour.svg";
import manfive from "../../../../assets/images/index/manfive.png";
import mansix from "../../../../assets/images/index/mansix.png";
import manseven from "../../../../assets/images/index/manseven.png";
import lineone from "../../../../assets/images/index/lineone.png";
import linetwo from "../../../../assets/images/index/linetwo.png";
import linethree from "../../../../assets/images/index/linethree.png";
import linefour from "../../../../assets/images/index/linefour.png";
import linefive from "../../../../assets/images/index/linefive.png";
import "./index.css";

function Custom() {
  return (
    <div className="container-fluid custom_container">
      <div className="row folio">
        <h1 className="portfolio_section_title">Have a custom requirement?</h1>
        <h1 className="portfolio_section_title">It’s how we work</h1>
      </div>

      <div className="row structure">
        <div className="col-4">
          <div className="row manone">
            <div className="man">
              <img
                src={manone}
                alt="Man"
                id="div1"
                className="img-fluid"
                width="280px"
                height="160px"
              ></img>
            </div>
          </div>
        </div>
        <div className="col-8">
          <div className="row indus_process1">
            <div className="col-1 numbering">
              <span className="order_num">1</span>
            </div>
            <div className="col-9">
              <h1 className="process">Understanding the business</h1>
              <p className="work">
                We build our products with the first principle of understanding
                the end-to-end business model is our first objective. We capture
                the operating model and data points at each human and computer
                interaction.{" "}
              </p>
            </div>
            <div className="col-2 empty"></div>
          </div>
        </div>
      </div>
      <div className="row structure">
        <div className="lineone">
          <img src={lineone} alt="Man" className="img-fluid curved-line"   style={{ paddingLeft: "130px", marginLeft: "63px" }}></img>
        </div>
      </div>
      <div className="row structure">
        <div className="col-8">
          <div className="row">
            <div className="col-2 empty"></div>
            <div className="col-1 numbering">
              <span className="order_num">2</span>
            </div>
            <div className="col-9">
              <div className="row indus_process2" id="div2">
                <h1 className="process">Analysis of the Requirements</h1>
                <p className="work">
                  Once we have the correct understanding of the business, our
                  team of experts including architects,business Analysts, data
                  scientists, user experience designers and software engineer
                  sit together to craft a product that will have a
                  Transformational experience to the business
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 man">
          <div className="row">
            <div className="mantwo">
              <img
                src={mantwo}
                alt="Man"
                className="img-fluid"
                width="204px"
                height="178px"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="row dot">
        <div className="linetwo">
          <img src={linetwo} alt="Man"
           className="img-fluid"
           style={{ marginLeft: "-97px", width:"70%" }}
         
           ></img>
        </div>
      </div>
      <div className="row">
        <div className="col-4 man">
          <div className="manthree">
            <div className="man">
              <img
                src={manthree}
                alt="Man"
                className="img-fluid"
                height="130px"
                width="165px"
              ></img>
            </div>
          </div>
        </div>
        <div className="col-8">
          <div className="row">
            <div className="col-1">
              <span className="order_num">3</span>
            </div>
            <div className="col-9">
              <div className="row indus_process1">
                <h1 className="process">Creating a custom product</h1>
                <p className="work">
                  At Delnie we craft a unique software product for our clients
                  after the analysis of the requirements. Our uniqueness lies in
                  creating custom products with the evolving business needs. We
                  bring to your table the perfect mix of modern advance
                  technologies that enhances your business. We create the exact
                  missing peice of your puzzle, and that’s our USP
                </p>
              </div>
            </div>
            <div className="col-2 empty"></div>
          </div>
        </div>
      </div>
      <div className="row structure">
        <div className="linethree">
          <img
            src={linethree}
            alt="Man"
            className="img-fluid curved-line"
            style={{ marginLeft: "40px", paddingLeft :"120px" }}
          ></img>
        </div>
      </div>
      <div className="row">
        <div className="col-8">
          <div className="row">
            <div className="col-2 empty"></div>
            <div className="col-1 numbering">
              <span className="order_num">4</span>
            </div>
            <div className="col-9">
              <div className="row indus_process2">
                <h1 className="process">Agile Process</h1>
                <p className="work">
                  Our SDLC follows the agile methodology and by that we mean by
                  break the complexity of requirements in several phases. With
                  an increased collaboration between the stake holders and the
                  project development team, we focus on delivering changes
                  keeping in mind the real time inputs and suggestions coming in
                  from the stakeholders thus improving the quality of product at
                  every stage.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 man">
          <div className="row">
            <div className="manfour">
              <img
                src={manfour}
                alt="Man"
                className="img-fluid"
                height="140px"
                width="214px"
              ></img>
            </div>
          </div>
        </div>
      </div>

      <div className="row structure">
        <div className="linefour">
          <img src={linefour} alt="Man" 
          style={{ marginLeft: "35px", paddingLeft :"65px" }}
          className="img-fluid curved-line"></img>
        </div>
      </div>
      <div className="row structure">
        <div className="col-4 man">
          <div className="row manfive">
            <div className="man">
              <img
                src={manfive}
                alt="Man"
                className="img-fluid"
                height="113px"
                width="161px"
              ></img>
            </div>
          </div>
        </div>
        <div className="col-8">
          <div className="row">
            <div className="col-1">
              <span className="order_num">5</span>
            </div>
            <div className="col-9">
              <div className="row indus_process1">
                <h1 className="process">Product Launch</h1>
                <p className="work">
                  {" "}
                  Once we have completed all the review and all the approvals
                  are in place, the beta version of the product is released and
                  the GA launch goes in after our final round of review.
                  Congratulations! We have given you the perfect pair of wings
                  and your dreams are good to fly high.
                </p>
              </div>
            </div>
            <div className="col-2 empty"></div>
          </div>
        </div>
        <div className="row dot">
          <div className="linefive">
            <div className="linefive">
              <img
                src={linefive}
                alt="Man"
                style={{ paddingRight:"179px", marginLeft:"-193px", width:"95%" }}
                className="img-fluid "
              ></img>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <div className="row">
              <div className="col-2 empty"></div>
              <div className="col-1 numbering">
                <span className="order_num">6</span>
              </div>
              <div className="col-9">
                <div className="row indus_process2">
                  <h1 className="process">
                    Increase Efficiency, Productivity and Margins
                  </h1>
                  <p className="work">
                    We Promise that our products will have significant impact on
                    the business increasing the efficiency, productivity and
                    profit margins of the business. We also provide you the KPI
                    metric data insights to track all the improvements.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 man">
            <div className="row">
              <div className="mansix">
                <img
                  src={mansix}
                  alt="Man"
                  className="img-fluid"
                  height="130px"
                  width="175px"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row dot">
        <div className="linetwo">
          <img src={linetwo} alt="Man"
           style={{ marginLeft:"-141px", width:"73%" }}
          className="img-fluid"></img>
        </div>
      </div>
      <div className="row">
        <div className="col-4 man">
          <div className="manthree">
            <div className="man">
              <img
                src={manseven}
                alt="Man"
                className="img-fluid"
                height="126px"
                width="138px"
              ></img>
            </div>
          </div>
        </div>
        <div className="col-8">
          <div className="row">
            <div className="col-1">
              <span className="order_num">7</span>
            </div>
            <div className="col-9">
              <div className="row indus_process1">
                <h1 className="process">
                  {" "}
                  Real-time support and Regular Upgrades
                </h1>
                <p className="work">
                  We understand how critical are our products for the day-to-day
                  operations of our clients thus we have a 24/7 client support
                  making sure there is negligible downtime of our products. As
                  the business scales up and matures our products constantly
                  upgrades to meet the changing requirements.
                </p>
              </div>
            </div>
            <div className="col-2 empty"></div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}
export default Custom;
