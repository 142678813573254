
import react from "../../../../../assets/images/stack-landing/react.png"; // Replace with actual path
import tailwind from "../../../../../assets/images/stack-landing/tailwind.png";
import nextjs from "../../../../../assets/images/stack-landing/nextjs.png";
import nodejs from "../../../../../assets/images/stack-landing/nodejs.png";
import expressjs from "../../../../../assets/images/stack-landing/expressjs.png";
import mongodb from "../../../../../assets/images/stack-landing/mongodb.png";
import postgresql from "../../../../../assets/images/stack-landing/postgresql.png";
import aws from "../../../../../assets/images/stack-landing/aws.png";
import docker from "../../../../../assets/images/stack-landing/docker.png";
import cicd_pipeline from "../../../../../assets/images/stack-landing/ci-cd-pipeline.png";

const technologies = {
  frontend: [
    {
      src: react,
      name: "React.js",
      desc: "For interactive and responsive user interfaces.",
    },
    {
      src: tailwind,
      name: "Tailwind CSS",
      desc: "For sleek, modern, and responsive designs.",
    },
    {
      src: nextjs,
      name: "Next.js",
      desc: "Ensuring optimal SEO and fast loading times.",
    },
  ],
  backend: [
    {
      src: nodejs,
      name: "Node.js",
      desc: "Efficient server-side processing for high performance.",
    },
    {
      src: expressjs,
      name: "Express.js",
      desc: "RESTful APIs for seamless communication between frontend and backend.",
    },
    {
      src: mongodb,
      name: "MongoDB",
      desc: "NoSQL database for handling complex data structures.",
    },
    {
      src: postgresql,
      name: "PostgreSQL",
      desc: "Robust relational database management.",
    },
  ],
  devOps: [
    { src: aws, name: "AWS", desc: "For scalable, reliable cloud hosting." },
    {
      src: docker,
      name: "Docker",
      desc: "Containerization for consistent deployments.",
    },
    {
      src: cicd_pipeline,
      name: "CI/CD Pipeline",
      desc: "Automating deployments for faster updates.",
    },
  ],
};

// const StackSuggestion: React.FC = (props:any) => {
// function StackSuggestion(props: any) {
const StackSuggestion = (props: any) => {
  // Helper function to render the tech stack in each category
  const renderTechStack = (
    techArray: { src: string; name: string; desc: string }[]
  ) =>
    techArray.map((tech, index) => (
      <div key={index} className="grid grid-cols-12 pl-1 mt-4">
        <div className="col-span-2">
          <img src={tech.src} alt={tech.name} className="w-full" />
        </div>
        <div className="col-span-10">
          <p className="text-justify text-black font-lato text-[14px] font-bold">
            {tech.name}
          </p>
          <span className="text-justify font-lato text-[14px]">
            {tech.desc}
          </span>
        </div>
      </div>
    ));

  return (
    <>
      {/* Stack Suggestion Section for Desktop */}
      <div className="hidden md:block mt-[200px]">
        <div className="grid grid-cols-10 gap-x-[22px]">
          <div className="col-span-4 bg-black text-white rounded-r-[40px] flex items-center justify-end">
            <span className="block text-[48px] font-lato pr-[20px] text-right">
              Stack Suggestion
              <br />
              <span className="inline-block">for Your Website</span>
            </span>
          </div>
          <div className="col-span-6 text-black">
            <div className="p-[20px] pr-[70px]">
              <p
                className="text-[24px] font-lato text-black space-y-3"
                style={{ lineHeight: "40px" }}
              >
                We leverage cutting-edge technologies to ensure your website
                performs optimally. Our robust tech stack guarantees
                lightning-fast speed, top-level security, seamless scalability,
                and an exceptional user experience on every device.
              </p>
            </div>
          </div>
        </div>

        {/* Tech Stack Section */}
        <div className="grid grid-cols-12 p-3 m-4">
          <div className="col-span-4 flex flex-col text-justify pl-[30px] m-4 ml-[20px]">
            <h4 className="text-justify font-lato">FRONTEND</h4>
            {renderTechStack(technologies.frontend)}
          </div>

          <div className="col-span-4 flex flex-col text-justify pl-[30px] m-4 ml-[20px]">
            <h4 className="text-justify font-lato">BACKEND</h4>
            {renderTechStack(technologies.backend)}
          </div>

          <div className="col-span-4 flex flex-col text-justify pl-[30px] m-4 ml-[20px]">
            <h4 className="text-justify font-lato">DEV-OPS & HOSTING</h4>
            {renderTechStack(technologies.devOps)}
          </div>
        </div>

        {/* Call to Action */}
        <div className="mt-16 text-center">
          <h4 className="text-3xl font-extrabold mb-4 text-black">
            Not sure which stack to choose?
          </h4>
          <p className="text-lg text-gray-600 mb-6">
            Contact us for a free consultation, and we'll guide you through the
            best stack for your project.
          </p>
          <button
              onClick={props.openEnquiryForm}
              className="px-8 py-3 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-full font-semibold shadow-lg hover:bg-gradient-to-l transition"
          >
            Get Free Consultation &rarr;
          </button>
        </div>
      </div>

      {/* Mobile Stack Suggestion Section */}
      <div className="md:hidden block mt-[200px]">
        <div className="w-2/3 bg-black text-white rounded-r-[12.91px] flex items-center justify-end">
          <span className="block text-base font-lato font-medium leading-[22.8px] pr-[20px] text-right p-[5px]">
            Stack Suggestion
            <br />
            <span className="inline-block">for Your Website</span>
          </span>
        </div>

        <div className="text-black p-[20px] pr-[70px]">
          <p className="text-xs font-lato text-[#2E2E2E] leading-[15.6px] space-y-3">
            We utilize the latest technologies to ensure your website performs
            at its best. Our stack guarantees speed, security, scalability, and
            a fantastic user experience across all devices.
          </p>
        </div>

        <div className="pl-6 pr-6">
          <div className="flex flex-col text-justify mb-6">
            <h4 className="text-justify font-lato font-bold">FRONTEND</h4>
            {renderTechStack(technologies.frontend)}
          </div>

          <div className="flex flex-col text-justify mb-6">
            <h4 className="text-justify font-lato font-bold">BACKEND</h4>
            {renderTechStack(technologies.backend)}
          </div>

          <div className="flex flex-col text-justify">
            <h4 className="text-justify font-lato font-bold">
              DEV-OPS & HOSTING
            </h4>
            {renderTechStack(technologies.devOps)}
          </div>
        </div>

        {/* Call to Action Section */}
        <div className="mt-12 text-center">
          <h4 className="text-2xl md:text-3xl font-extrabold mb-4 text-black">
            Not sure which stack to choose?
          </h4>
          <p className="text-base md:text-lg text-gray-600 mb-6 px-4">
            Contact us for a free consultation, and we'll guide you through the
            best stack for your project.
          </p>
          <button
            // onClick={handleOpenModal}
            onClick={props.openEnquiryForm}
            className="px-6 md:px-8 py-3 bg-gradient-to-r from-blue-500 to-purple-600 text-white rounded-full font-semibold shadow-lg hover:bg-gradient-to-l transition"
          >
            Get Free Consultation &rarr;
          </button>
        </div>
      </div>

      {/* Enquiry Form Modal */}
      {/* {isModalOpen && (
        <EnquiryForm isOpen={isModalOpen} onClose={handleCloseModal} />
      )} */}
    </>
  );
};

export default StackSuggestion;
