import { useEffect } from "react";
import SEO from "../../common/SEO";
import OurProducts from "./our-products";
import ProductsPortfolio from "./product-folio/product-folio";
import ProductTabs from "./products-tabs";
import "./products.css";
import WhyDelnie from "./why-del/why-delnie";

function Products() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <SEO
        title="Our Products"
        description="favhiker : Taste Connect and Award. Your Social Gateway to the Culinary World"
        keywords="favhiker, Taste Connect and Award, brighter futures, Social Gateway, Culinary World"
        image="../../assets/images/hero_section_bg.png"
        url="https://www.delnie.com/product-folio"
      /> */}

      <OurProducts />

      <ProductTabs />

      <WhyDelnie />

      <ProductsPortfolio />
    </>
  );
}
export default Products;
