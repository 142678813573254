import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"; // Importing necessary styles
import { Button } from "../../../ui/button";
import { get, post } from "../../../../../utils/api";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import Snackbar from "../../../ui/snackbar";
import axios from "axios";

const EnquiryForm: React.FC<any> = (props) => {

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  if (!props.isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-2xl w-full max-w-lg overflow-hidden">
        {/* Modal Header */}
        <div className="bg-gradient-to-r from-blue-600 to-purple-600 text-white py-4 px-6 flex justify-between items-center">
          <h2 className="text-xl font-semibold">Enquire Now</h2>
          <button
            onClick={props.onClose}
            className="text-gray-300 hover:text-white transition"
          >
            &times;
          </button>
        </div>

        {/* Modal Body */}
        <div className="px-6 py-8">
          {props.error && <p className="text-red-500 mb-4">{props.error}</p>}

          <form onSubmit={props.handleSubmit}>
            <div className="space-y-4 md:space-y-6">
              {/* Name Input */}
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="text-sm font-medium text-gray-700 mb-1"
                >
                  Your Name
                </label>
                <input
                  id="name"
                  placeholder="Your Name"
                  value={props.name}
                  onChange={(e) => props.setName(e.target.value)}
                  className="w-full text-base h-10 md:h-12 px-4 rounded-lg border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 transition-colors duration-300 ease-in-out"
                />
              </div>

              {/* Phone Input */}
              <div className="flex flex-col">
                <label
                  htmlFor="phone"
                  className="text-sm font-medium text-gray-700 mb-1"
                >
                  Phone Number
                </label>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "15px",
                    border: isFocused
                      ? "1px solid #3b82f6"
                      : "1px solid #d1d5db", // Blue border on focus
                    borderRadius: "12px", // Rounded corners (like rounded-lg)
                    boxShadow: isFocused
                      ? "0 0 0 4px rgba(59, 130, 246, 0.3)" // Blue ring effect on focus
                      : "0 1px 2px rgba(0, 0, 0, 0.05)", // Subtle shadow (shadow-sm)
                    transition:
                      "border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out", // Smooth transition with duration-300 and ease-in-out
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 4px",
                      borderRight: "1px solid #ccc", // Border between dropdown and input
                      position: "relative", // Make dropdown's position relative for arrow placement
                      width: "120px", // Small width to show only the code
                    }}
                  >
                    <select
                      value={props.countryCode}
                      onChange={props.handleCountryChange}
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        fontSize: "16px",
                        padding: "8px 12px",
                        width: "100%", // Full width for the dropdown
                        appearance: "none", // Hides the default dropdown arrow
                        cursor: "pointer",
                        textAlign: "center", // Center-align the country code
                      }}
                    >
                      {props.countryCodeData.map((country: any) => (
                        <option
                          key={country.country_code}
                          value={country.country_code}
                        >
                          {/* When the dropdown is expanded, show both short_name and country code */}
                          {props.countryCode === country.country_code
                            ? country.country_code // Display only the code after selection
                            : `${country.short_name} ${country.country_code}`}{" "}
                          {/* Show both when selecting */}
                        </option>
                      ))}
                    </select>

                    {/* Custom dropdown arrow inside the select box */}
                    {/* <span
                      style={{
                        position: "absolute",
                        right: "2px", // Position the arrow at the right of the select box
                        pointerEvents: "none", // Arrow should not block the dropdown
                        fontSize: "14px",
                      }}
                    >
                      ▼
                    </span> */}
                  </div>

                  <input
                    type="text"
                    placeholder="Phone"
                    value={props.phone}
                    onChange={props.handlePhoneChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    style={{
                      padding: "12px",
                      fontSize: "16px",
                      border: "none",
                      width: "100%", // Ensure the input takes up the remaining space
                      backgroundColor: "#fff", // White background to match the dropdown
                      borderRadius: "0 8px 8px 0", // Rounded corners only on the right side
                    }}
                  />
                </div>
              </div>

              <p className="text-xs font-light text-gray-500">
                Your information is safe with us. We respect your privacy.
              </p>

              <Button
                type="submit"
                className="py-3 md:py-4 px-6 bg-gradient-to-r from-blue-600 to-purple-600 text-white text-base md:text-lg font-semibold rounded-lg hover:from-purple-600 hover:to-blue-600 transition duration-300 ease-in-out shadow-lg focus:outline-none focus:ring-2 focus:ring-purple-600 !w-full max-w-lg"
              >
                Request Free Consultation
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EnquiryForm;
